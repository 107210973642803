import React, { useEffect, useState } from "react";
import styles from "../../styles/modal.module.scss";
import close_btn from "../../assets/close-btn.svg";
import Ada from "../../assets/ada.svg";
import Button from "../button";
import Select from "../select";

function EarlyAccess({ setShowModal }) {
  const [countries, setCountries] = useState([]);
  const [name, setName] = useState("");
  const [country, setCountry] = useState("Country");
  const [amount, setAmount] = useState(0);
  const [telegram, setTelegram] = useState("");
  const [referrer, setReferrer] = useState(
    "How did you hear about Bash Token?"
  );
  const [ip, setIp] = useState("");
  const [error, setError] = useState(false);
  const [detectedCountry, setDetectedCountry] = useState("");

  const socials = [
    { name: "Twitter", url: "https://twitter.com/bashoswap" },
    { name: "Telegram", url: "https://t.me/bashoswap" },
    { name: "Medium", url: "https://medium.com/bashoswap" },
    { name: "Facebook", url: "https://www.facebook.com/bashoswap" },
    { name: "Github", url: "" },
    { name: "Reddit", url: "https://www.reddit.com/r/bashoswap" },
  ];

  const getUserIpAddress = () => {
    fetch("https://peaceful-tundra-06709.herokuapp.com/api/v1/users/location")
      .then((response) => response.json())
      .then((data) => {
        setIp(data?.data?.ip);
        setDetectedCountry(data?.data?.country);
      });
  };

  useEffect(() => {
    getUserIpAddress();
  }, []);

  const getData = () => {
    fetch("../utils/countries.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setCountries(myJson);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const data = {
    name: name,
    country: country,
    amount: parseInt(amount),
    telegram_username: telegram,
    referrer: referrer,
    ip: ip,
  };

  const joinWhitelist = () => {
    if (detectedCountry !== country) {
      setError(true);
    } else {
      setError(false);
      fetch(
        "https://peaceful-tundra-06709.herokuapp.com/api/v1/users/whitelist",
        {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((data) => setShowModal("success"))
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  return (
    <div className={styles.modal_con}>
      <div className={styles.early_access}>
        <img
          src={close_btn}
          alt="close"
          className={styles.close_btn}
          onClick={() => {
            setShowModal("none");
          }}
        />
        <div className={styles.early_inner_con}>
          <h1>Get early access</h1>
          <p>Fill in your details to get early access to bash token</p>
          <input
            type="text"
            className={styles.name}
            placeholder="Enter Name"
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
          />
          <input
            type="text"
            className={styles.ip}
            placeholder="IP address"
            value={ip}
            onChange={(e) => {
              setIp(e.target.value);
            }}
            readOnly
          />
          <Select
            items={countries}
            selected={country}
            action={(country) => {
              setError(false);
              setCountry(country);
            }}
          />

          <h6 className={styles.error}>
            {error && "Country does not match with IP address"}
          </h6>
          <div className={styles.custom_input}>
            <div className={styles.custom_input_top}>
              <img src={Ada} alt="" />
              <input
                type="text"
                placeholder="Amount"
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                value={amount}
              />
            </div>
            <div className={styles.custom_input_bottom}>
              <h3>Quantity: 0</h3>
              <h3>Selling at: 0.055 ADA</h3>
            </div>
          </div>
          <input
            type="text"
            className={styles.input_telegram}
            placeholder="Telegram Username"
            onChange={(e) => {
              setTelegram(e.target.value);
            }}
            value={telegram}
          />
          <Select items={socials} selected={referrer} action={setReferrer} />
          <div className={styles.button_wrapper}>
            <Button
              text="Join whitelist"
              action={() => {
                joinWhitelist();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EarlyAccess;
