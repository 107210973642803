import React from "react";
import styles from "../styles/select.module.scss";
import ArrowDown from "../assets/arrow-down.svg";
import { useState } from "react";

function Select({ items, action, selected }) {
  const [showDropdown, setShowDropdown] = useState(false);
  // const [selected, setSelected] = useState(placeholder);

  return (
    <div
      className={styles.select_con}
      onClick={() => {
        setShowDropdown(!showDropdown);
      }}
      style={{
        borderBottomRightRadius: showDropdown ? "0px" : "6px",
        borderBottomLeftRadius: showDropdown ? "0px" : "6px",
      }}
    >
      <div className={styles.select_inner_con}>
        <h6>{selected}</h6>
        <img
          src={ArrowDown}
          alt=""
          style={{
            transform: showDropdown ? "rotate(180deg)" : null,
            paddingLeft: showDropdown ? "15px" : "0px",
          }}
        />
      </div>
      <div
        className={styles.list_con}
        style={{
          display: showDropdown ? "block" : "none",
          borderTopRightRadius: showDropdown ? "0px" : "6px",
          borderTopLeftRadius: showDropdown ? "0px" : "6px",
        }}
      >
        {items.map((item, index) => {
          return (
            <h1
              key={index}
              onClick={() => {
                action(item.name);
              }}
            >
              {item.name}
            </h1>
          );
        })}
      </div>
    </div>
  );
}

export default Select;
