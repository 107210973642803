import React from "react";
import styles from "../styles/navbar.module.scss";
import logo from "../assets/logo.svg";

function NavBar({ setShowModal }) {
  return (
    <div className={styles.nav_con}>
      <div className={styles.nav_inner_con}>
        <div>
          <img src={logo} alt="" />
        </div>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/"
            >
              Overview
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/faq"
            >
              FAQs
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/contact"
            >
              Talk To Us
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/hiring"
            >
              Careers
            </a>
          </li>
          <li
            className={styles.connec_wallet}
            onClick={() => {
              setShowModal("connectwallet");
            }}
          >
            Connect Wallet
          </li>
        </ul>
        <span
          className={styles.menu_btn}
          onClick={() => {
            setShowModal("menu");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </span>
      </div>
    </div>
  );
}

export default NavBar;
